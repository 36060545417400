import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Endpoints } from "../constants/Endpoints";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decreaseCartCount } from "../redux/ActionCreator";
import { MdOutlineCancel } from "react-icons/md";


const CourseDetailItem = ({ data, setUpdate, batches, update }) => {

  const dispatch = useDispatch();
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const [batchData, setBatchData] = useState([]);

  useEffect(() => {
    fetchBatches();
  }, []);
  

  
  const fetchBatches = async () => {
    try {
      const res = await axios.get(
        `${Endpoints.BASE_URL}/batches/get/list?course_id=${data.course_id}`
      );
      setBatchData(res.data.data || []);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const removeItem = async () => {
    try {
      const res = await axios.delete(
        `${Endpoints.BASE_URL}/cart/remove-course-from-cart`,
        {
          headers: {
            Authorization: `Bearer ${profileDetails.token}`,
          },
          data: { cart_id: data.id },
        }
      );
      dispatch(decreaseCartCount());
      setUpdate(!update)
    } catch (error) {
      console.error("Error removing course from cart:", error);
    }
  };

  const navigate = useNavigate();
 

  let currentBatch = useMemo(() => {
      for(let i of batchData){
        if(i.id === data.batch_id){
          return i;
        }
      }
  },[batchData])

  const now = new Date(Date.now());
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  
 
  return (
    <div className="bg-white shadow-md rounded-lg">
     { <div className="space-y-4">
        <div className="p-4 border-gray-300 shadow-md transform hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease-in-out box-border border flex justify-between items-center rounded-lg">
          <div className="flex">
            <div className="">  
              <button
              className="hover:bg-red-600 hover:text-white rounded-full shadow-sm text-gray-800 !text-3xl m-2 transition duration-300 ease-in-out"
              onClick={removeItem}
            >
              <MdOutlineCancel />
            </button>
            </div>
            <div>
              <h3 className="text-lg max-sm:text-base font-medium">
              {data?.course?.title}
              </h3>
              <div className="flex flex-col text-[14px] my-2 mt-0 gap-1">
                <p>
                  <span className="font-bold">Duration: - </span>
                  <span>{data?.course?.duration}</span>
                </p>
                <p>
                  <span className="font-bold">Batch Start Date: - </span>
                  <span className="text-[12px] md:text-[16px]">
                    {currentBatch?.start_date} and
                  </span>
                  <span className=""> ends on </span>
                  <span className="text-[12px] md:text-[16px]">
                    {currentBatch?.end_date}
                  </span>
                </p>
                
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-xl max-sm:text-base font-bold">₹{data?.amount}</p>
            
          </div>
        </div>
      </div>}
    </div>
  );
};

export default CourseDetailItem;
