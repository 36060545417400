import axios from "axios";
import React, { useState } from "react";
import { Endpoints } from "../constants/Endpoints";
import { useNavigate } from "react-router-dom";
import { routes } from "../constants/routes";
import { IoIosArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { increaseCartCount } from "../redux/ActionCreator";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const PackageCard = ({ packageData }) => {
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [relatives, setRelatives] = useState({ package_id: -1, course_id: -1 });
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const profileDetails = useSelector(({ app }) => app.profileDetails);
  const dispatch = useDispatch();

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
    return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  }

  const fetchBatches = async (data) => {
    try {
      setBatches([]);
      const res = await axios.get(
        `${Endpoints.BASE_URL}/batches/get/list?course_id=${data.id}`
      );

      if (res?.data?.data && res?.data?.data.length > 0) {
        let status = false;
        for (let i of res?.data?.data) {
          if (i.status !== "Closed") {
            status = true;
          }
        }

        if (!status) {
          toast.success("All batches closed.");
        }
      }
      setBatches(res.data.data);
    } catch (error) {
      console.error("Error fetching batches:", error);
    }
  };

  const toggleDropdown = (package_id, course_id) => {
    setDropdownVisible(!dropdownVisible);
    setRelatives({
      package_id: package_id,
      course_id: course_id,
    });
  };
  const handleDateSelect = async (batch_id, courseData, batch) => {
    setDropdownVisible(false);
  
  
    setSelectedPackages((prevSelectedPackages) => {
      const updatedPackages = [...prevSelectedPackages];
      
      // Check if the package exists in the selected packages array
      const existingPackageIndex = updatedPackages.findIndex(
        (pkg) => pkg.package_id === relatives.package_id
      );
  
      if (existingPackageIndex !== -1) {
        let updatedData = [...updatedPackages[existingPackageIndex].data];
  
        // Check if the selected date is already present for another course in the same package
        const sameDateIndex = updatedData.findIndex(
          (course) => course.selected_batch_date === formatDate(batch.start_date)
        );
  
        if (sameDateIndex !== -1) {
          toast.info("You have already selected this date for another course. Please choose a different date.");
          return prevSelectedPackages; 
        }
  
        // If no same date conflict, update or add the course
        const existingCourseIndex = updatedData.findIndex(
          (course) => course.course_id === courseData.id
        );
  
        if (existingCourseIndex !== -1) {
          updatedData[existingCourseIndex] = {
            course_id: courseData.id,
            requested_batch_id: batch_id,
            selected_batch_date: formatDate(batch.start_date),
            category_id: courseData.category_id,
          };
        } else {
          updatedData.push({
            course_id: courseData.id,
            requested_batch_id: batch_id,
            selected_batch_date: formatDate(batch.start_date),
            category_id: courseData.category_id,
          });
        }
  
        // Update the package data
        updatedPackages[existingPackageIndex] = {
          ...updatedPackages[existingPackageIndex],
          data: updatedData,
        };
      } else {
        // If the package doesn't exist, add the new package with the selected course data
        updatedPackages.push({
          package_id: relatives.package_id,
          data: [
            {
              course_id: courseData.id,
              requested_batch_id: batch_id,
              selected_batch_date: formatDate(batch.start_date),
              category_id: courseData.category_id,
            },
          ],
        });
      }
  
      return updatedPackages;
    });
  };
  
  

 
  // final add to cart
  const addToCart = async () => {
    if (selectedPackages.length === 0) {
      alert("Please select a package");
      return;
    }
    if (profileDetails.token) {
      try {
        const payload = {
          package_id: selectedPackages[0].package_id,
          courses: selectedPackages[0]?.data.map((course) => ({
            course_id: course.course_id,
            category_id: course.category_id,
          })),
        };
        // Add course to cart API call
        const cartResponse = await axios.post(
          `${Endpoints.BASE_URL}/cart/add-package-cart`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${profileDetails.token}`,
            },
          }
        );
        const linkPayload = {
          package_id: selectedPackages[0].package_id,
          candidate_id: profileDetails?.id,
          courses: selectedPackages[0]?.data.map((course) => ({
            course_id: course.course_id,
            requested_batch_id: course.requested_batch_id,
          })),
        };
        const linkBatchResponse = await axios.put(
          `${Endpoints.BASE_URL}/api/courses/bulkLinkBatch`,
          linkPayload
        );
        console.log("linkBatchResponse",linkBatchResponse)
        if (cartResponse && linkPayload) {
          navigate(`${routes.COURSE_BOOKING}/details`);
        }
      } catch (e) {}
    } else {
      navigate(routes.LOGIN);
    }
  };

  return (
    <div className="p-4">
      {packageData &&
        packageData.length > 0 ?
        packageData?.map((item, i) => (
          <div className=" drop-shadow rounded border px-5 py-3 my-4">
            <div className=" flex  justify-between w-full" key={i}>
              <div className="w-full">
                <div className=" flex justify-between items-center">

                <h2 className="text-xl font-semibold text-[#205277]">
                  {item?.packageName}
                </h2>
                <h2 className="text-xl font-semibold ">
                  ₹{item?.price}
                </h2>
                </div>
                <div className="flex gap-3 items-center sm:flex-row flex-col justify-between py-2">
                  <div className="sm:flex-[.5]">
                    <img
                      src={item?.packageImage}
                      alt=""
                      className="h-[130px] min-w-[170px] rounded-md"
                    />
                  </div>
                  <div className="sm:flex-[3]">
                    {item?.courses &&
                      item?.courses.length > 0 &&
                      item?.courses.map((data, idx) => (
                        <div
                          key={idx}
                          className="my-2 flex justify-between gap-3"
                        >
                          <h3 className="flex-[2]">
                            {idx + 1}. {data.title}
                          </h3>
                          <button
                            className="flex items-center font-bold text-[#205277] flex-[1] sm:whitespace-nowrap"
                            onClick={() =>
                              navigate(
                                `${routes.COURSE_BOOKING}/${data?.slug
                                  ?.replace(/\s+/g, "-")
                                  .toLowerCase()}-${data.id}`,
                                { state: { id: data.id } }
                              )
                            }
                          >
                            View Course Details
                          </button>

                          <div className="relative">
                            <button
                              className="bg-[#205277] p-2 px-8 rounded-xl flex-[1] z-0 text-white font-bold flex items-center sm:whitespace-nowrap"
                              onClick={() => {
                                toggleDropdown(item.id, data.id);
                                fetchBatches(data);
                              }}
                            >
                              {selectedPackages?.some(
                                (pkg) =>
                                  pkg.package_id === item.id &&
                                  pkg.data.some(
                                    (course) => course.course_id === data.id
                                  )
                              ) ? (
                                selectedPackages
                                  ?.find((pkg) => pkg.package_id === item.id)
                                  .data.find(
                                    (course) => course.course_id === data.id
                                  ).selected_batch_date
                              ) : (
                                <span className=" mx-10"> Select Batch</span>
                              )}{" "}
                              <IoIosArrowDropdown className="ml-2" />
                            </button>

                            {dropdownVisible &&
                              relatives?.course_id === data.id &&
                              relatives?.package_id === item?.id && (
                                <div className="h-auto absolute w-full top-0 mt-9 mx-2  bg-white rounded-lg shadow-xl z-[1000] overflow-y-scroll max-h-[200px]">
                                  {batches &&
                                    batches?.length > 0 &&
                                    batches?.map(
                                      (batch) =>
                                        batch.status !== "Closed" &&
                                        dayjs().isBefore(batch?.start_date) &&
                                        batch?.available_seats !== 0 && (
                                          <div
                                            key={batch.id}
                                            className="my-2 border-b flex justify-between items-center px-2 cursor-pointer"
                                            onClick={() =>
                                              handleDateSelect(
                                                batch.id,
                                                data,
                                                batch
                                              )
                                            }
                                          >
                                            <span className="p-2 text-gray-800 hover:bg-gray-200 font-semibold text-[14px]">
                                              {formatDate(batch?.start_date)}
                                            </span>
                                            <span className="text-blue-600 text-[12px]">
                                              ({batch?.available_seats} seats)
                                            </span>
                                          </div>
                                        )
                                    )}
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end my-2">
              <button
                className=" p-2 px-12 rounded-xl text-white font-bold bg-[#204159]"
                onClick={addToCart}
              >
                Book Now
              </button>
            </div>
          </div>
        ))
        :<div>
          <h1 className="text-3xl font-bold text-gray-800">No batches available
            </h1>
        </div>
      }
    </div>
  );
};

export default PackageCard;
